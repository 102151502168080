import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  Text,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { Learner, LearnerSyllabusTask } from "../../models";
import { getLearnerSyllabusTasks } from "../../services/blendedLearningService";
import YourProgressTasks from "./YourProgressTasks";
import CustomIcon from "./CustomIcon";
import { iconsMap, taskTypes } from "../../common/commonData";

import "../styles/Syllabus.scss";
import YourProgressTasksCount from "./YourProgressTasksCount";

/**
 * YourProgress component
 */
const useStyles = makeStyles({
  columnHeaderStyle: {
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
  },
  columnSubheaderStyle: {
    fontSize: "12px",
    marginBottom: "0.5rem",
  },
  rowHeaderStyle: {
    fontSize: "14px",
    alignItems: "center",
    ...shorthands.gap("0.5em"),
  },
  firstcolumnStyle:{
    width: "8em"
  },
  columnStyle:{
   width: "13.8em",
  },
  focusAreafirstcolumnStyle:{
    width: "25%",
  },
  focusArealastcolumnStyle:{
    width: "30%",
  },
  flagDot: {
    ...shorthands.borderRadius("50%"),
    width: "0.5em",
    height: "0.5em",
    backgroundColor: "#E93A39",
  },
  cellStyle: {
    minWidth: "maxContent",
  },
  blackCircle: {
    backgroundColor: tokens.colorNeutralBackgroundStatic,
    ...shorthands.borderRadius("50%"),
    width: "1.5em",
    height: "1.5em",
    color: "#fff",
    position: "relative",
    bottom: "0.75rem",
  },
  blackCircleText: {
    position: "relative",
    fontSize: "0.75em",
    fontWeight: "400",
  },
  miniSubheaderStyle: {
    fontSize: "12px",
    marginBottom: "0.5rem",
    lineHeight: ".2em",
  },
});

interface IComponentProps {
  learner: Learner;
  deliveryId: string;
  isShowFocusArea: boolean;
  channelId: string;
  isShowHeader: boolean;
  isShowRightColumn: boolean;
}

export const YourProgress: React.FunctionComponent<IComponentProps> = (
  props
) => {
  const {
    learner,
    deliveryId,
    isShowFocusArea,
    channelId,
    isShowHeader,
    isShowRightColumn,
  } = props;
  const [items, setItems] = React.useState<LearnerSyllabusTask[]>([]);
  const classes = useStyles();

  const { data: tasksData, isLoading: isTasksDataLoading } = useQuery({
    queryKey: ["UserTasksData"],
    queryFn: () => getLearnerSyllabusTasks(learner.id, deliveryId),
  });

  useEffect(() => {
    if (!isTasksDataLoading) {
      if (isShowFocusArea && channelId != "" && channelId != undefined) {
        const filtereddata =
          tasksData != null && tasksData.length > 0
            ? tasksData.filter(
                (x: LearnerSyllabusTask) =>
                  x.deliveryTopic.teamChannelId == channelId
              )
            : [];
        setItems(filtereddata);
        // setItems(tasksData);
      } else {
        setItems(tasksData);
      }
    }
  }, [tasksData, isTasksDataLoading]);

  const columnHeaderData =
    items != null && items.length > 0
      ? items
          .map((item) => item.deliveryTopic)
          .reduce((acc: string[], deliveryTopic) => {
            acc[deliveryTopic.weekNumber - 1] = deliveryTopic.topics?.name.trim();
            return acc;
          }, [])
      : [];

  const columnHeaders = isShowFocusArea
    ? columnHeaderData
    : columnHeaderData.filter(
        (item) => item !== "Next Steps" && item !== "Orientation"
      );

  const tasksPerWeek = (weekNumber: number) => {
    return items.filter((item) => item.deliveryTopic.weekNumber === weekNumber)
      .length;
  };

  const completedTasks = (weekNumber: number) => {
    return items.filter(
      (item) =>
        item.deliveryTopic.weekNumber === weekNumber && item.isTaskCompleted
    ).length;
  };

  return (
    <>
      {items != null && items.length > 0 && isShowFocusArea && (
        <Text className={classes.miniSubheaderStyle}>
          {items.filter((x) => x.isTaskCompleted).length}/{items.length} Tasks
          Completed
        </Text>
      )}
      {items != null && items.length > 0 ? (
        <Table aria-label="Table showing your progress"  tabIndex={0}>
          {isShowHeader && (
            <TableHeader> 
              <TableRow> 
                <TableHeaderCell className={classes.firstcolumnStyle}>
                </TableHeaderCell>
                {columnHeaders.map((header, index) => (
                  <TableHeaderCell className={classes.columnStyle} aria-hidden={false} role='cell'>
                    <span className={classes.blackCircle}> 
                      <Text className={classes.blackCircleText}>
                        {index + 1}
                      </Text>
                    </span>
                    <Text className={classes.columnHeaderStyle}>
                      {header}
                      <Text className={classes.columnSubheaderStyle}>
                        {completedTasks(index + 1)}/{tasksPerWeek(index + 1)}{" "}
                        Tasks Completed
                      </Text>
                    </Text>
                  </TableHeaderCell>
                ))}
                {isShowRightColumn && <TableHeaderCell className={classes.focusArealastcolumnStyle}></TableHeaderCell>}
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {taskTypes.map((taskType: string, index) => (
              <TableRow key={index}>
                <TableCell className={isShowHeader?classes.rowHeaderStyle:classes.focusAreafirstcolumnStyle}>
                  <TableCellLayout
                    media={
                      <CustomIcon
                        iconName={taskType as keyof typeof iconsMap}
                      />
                    }
                  >
                    <Text>{taskType}</Text>
                  </TableCellLayout>
                </TableCell>
                {columnHeaders.map((header: string, index) => (
                  <TableCell className={classes.cellStyle}>
                    <TableCellLayout>
                      <YourProgressTasks
                        tasks={items}
                        taskType={taskType}
                        weekNumber={index + 1}
                        learnerEmail={learner.email}
                      />
                    </TableCellLayout>
                  </TableCell>
                ))}
                {isShowRightColumn && (
                  <TableCell className={classes.focusArealastcolumnStyle}>
                    <Text className={classes.columnHeaderStyle}>
                      <Text className={classes.columnSubheaderStyle}>
                        <YourProgressTasksCount
                          tasks={items}
                          taskType={taskType}
                        />
                        Tasks Completed
                      </Text>
                    </Text>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Text>No more tasks for this course.</Text>
      )}
    </>
  );
};

export default YourProgress;
