import React, { useEffect, useState } from "react";
import { Clock16Regular } from "@fluentui/react-icons";
import { Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";

/**
 * SessionTimer Component
 */
export interface ISessionTimerProps {
  timerDurationInMinutes: number;
}

const useStyles = makeStyles({
  root: {
    color: tokens.colorNeutralForeground1,
    left: "0.5em",
    backgroundColor: tokens.colorBrandForegroundLink,
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    paddingLeft: "0.4em",
    paddingRight: "0.5em",
    ...shorthands.borderRadius("0.25em"),
  },
  text: {
    fontSize: "12px",
    fontWeight: "400",
  },
});

export const SessionTimer: React.FunctionComponent<ISessionTimerProps> = (
  props
) => {
  const { timerDurationInMinutes } = props;

  const [minutes, setMinutes] = useState(timerDurationInMinutes);

  const classes = useStyles();

  useEffect(() => {
    if (minutes !== 0) {
      const timer = setInterval(() => setMinutes(minutes - 1), 60 * 1000);
      return () => clearInterval(timer);
    }
  }, [minutes]);

  return (
    <>
      {minutes > 0 && minutes < 30 ? (
        <span className={classes.root}>
          <Clock16Regular  />
          <span aria-label={`in ${Math.round(minutes)} minutes`}></span>
          <span aria-hidden="true"  className={classes.text}>&nbsp;in {Math.round(minutes)} m</span>
        </span>
      ) : null}
    </>
  );
};

export default SessionTimer;
